import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { columnCards, sliderCompanies, subpageContent } from "../utils/products/subpageInformation";
import { bannerCMS } from "../utils/generalInformation";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";
import InfoImage from "../components/info-image/info-image";
import Banner from "../components/banner/banner";




// markup
const Landlord = () => {


    return (
        <Layout
            SEOTitle={"Landlord Legal Protection - United Legal Assistance"}
            SEODescription={"Our Landlord Professional Fees policy, is available to any landlord purchasing a buy to let residential property Insurance."}
            footerDisclaimer={""}>

            <InfoImage props={subpageContent} origin="landlord" />
            <InfoImage props={subpageContent} origin="fees" />
            <InfoImage props={subpageContent} origin="result" />

            <Banner props={bannerCMS} />

            <Comparison props={columnCards} />

            <ImageCarousel props={sliderCompanies} />

        </Layout>
    )

}

export default Landlord